.fade-750ms-appear {
  opacity: 0;
  transition: opacity 750ms;
}
.fade-750ms-appear-active {
  opacity: 1;
  transition: opacity 750ms;
}

.fade-750ms-enter {
  opacity: 0;
  transition: opacity 750ms;
}

.fade-750ms-enter-active {
  opacity: 1;
  transition: opacity 750ms;
}

.fade-750ms-exit {
  opacity: 1;
  transition: opacity 750ms;
}

.fade-750ms-exit-active {
  opacity: 0;
  transition: opacity 750ms;
}

.fade-750ms-exit-done {
  opacity: 0;
  transition: opacity 750ms;
}

.videos {
  .fade-750ms-exit {
    opacity: 1;
    width: 336px !important;
    max-width: 336px !important;
    transform: translate3d(0, 0, 0);
    margin-right: 7px !important;
    margin-left: 7px !important;
    transition: opacity 750ms, margin-left 750ms, width 750ms, margin-right 750ms, max-width 750ms,
      transform 750ms;
  }

  .fade-750ms-exit-active {
    opacity: 0;
    width: 0px !important;
    max-width: 0px !important;
    transform: translate3d(-336px, 0, 0);
    margin-right: 0px !important;
    margin-left: 0px !important;
    transition: opacity 750ms, margin-left 750ms, width 750ms, margin-right 750ms, max-width 750ms,
      transform 750ms;
  }
}

.instant-disappear {
  .fade-750ms-exit {
    opacity: 0;
    width: 0px !important;
    max-width: 0px !important;
    margin: 0px !important;
    height: 0px !important;

    transition: none !important;
  }

  .fade-750ms-exit-active {
    opacity: 0;
    width: 0px !important;
    max-width: 0px !important;
    margin: 0px !important;
    height: 0px !important;

    transition: none !important;
  }

  .fade-750ms-exit-done {
    opacity: 0;
    width: 0px !important;
    max-width: 0px !important;
    margin: 0px !important;
    height: 0px !important;

    transition: none !important;
  }
}

.fade-250ms-enter {
  opacity: 0;
  transition: opacity 250ms;
}

.fade-250ms-enter-active {
  opacity: 1;
  transition: opacity 250ms;
}

.fade-250ms-exit {
  opacity: 1;
  transition: opacity 250ms;
}

.fade-250ms-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.fade-250ms-exit-done {
  opacity: 0;
  transition: opacity 250ms;
}

.SlideHorizontal-appear {
  opacity: 0;
  transform: translateX(-25%);
  transition: opacity 250ms, transform 500ms;
}
.SlideHorizontal-appear-active {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 250ms, transform 500ms;
}

.SlideHorizontal-enter {
  opacity: 0;
  transform: translateX(-60%);
  transition: opacity 250ms, transform 500ms;
}

.SlideHorizontal-enter-active {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 250ms, transform 500ms;
}

.SlideHorizontal-enter-done {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 250ms, transform 500ms;
}

.SlideHorizontal-exit {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 250ms, transform 500ms;
}

.SlideHorizontal-exit-active {
  opacity: 0;
  transform: translateX(-60%);
  transition: opacity 250ms, transform 500ms;
}

.SlideHorizontal-exit-done {
  opacity: 0;
  transform: translateX(-60%);
  transition: opacity 750ms, transform 1000ms;
}
