@import "~bootstrap/scss/bootstrap.scss";
@import "./override.scss";

html {
	background: #070711;
	background-color: #070711;
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-attachment: fixed;
	padding: 0;

	background-color: var(--backgroundColor);
	object-fit: cover;
	background-position-x: center;
	background-position-y: center;
	background-size: var(--backgroundImgSize);
	background-repeat: var(--backgroundImgRepeat);
	color: var(--textColor1);
	// background-image: var(--backgroundImg);
}

body.modal-open {
	padding-right: 0 !important;
	overflow: visible !important;
}

code {
	font-family: "Fira Code", monospace, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:hover {
	text-decoration: none;
}

:root,
html,
body,
main,
#root,
* {
	scrollbar-color: var(--scrollbarColors);
	scrollbar-width: thin !important;
}

html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
	transition-delay: 0 !important;
	transition: all 750ms !important;
}

.tooltip {
	max-width: 500px;
}

.navbar-dark .navbar-nav a.nav-link.active {
	color: var(--textColor2Hover);
}

a {
	text-decoration: none;
}

.fade.modal-backdrop.show {
	opacity: 0;
}
// .modal-dialog {
//   margin: 0;
// }

input[type="number"] {
	-moz-appearance: textfield;
	appearance: textfield;
	margin: 0;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
