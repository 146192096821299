.tooltip-inner {
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: start;
  background-color: #161616f1;
  max-width: unset;
  border-radius: 3px;
  font-size: 1rem;
}

.btn {
  padding: 0.5rem 0.75rem;
  // margin: 1rem 0;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: var(--refreshButtonShadow);
}

.dropdown-item {
  color: #ffffff;
  transition: all 0.1s ease-in-out;
}

.example-enter {
  opacity: 0.01;
}

.modal-content {
  background: var(--navigationbarBackground) !important;
  border-color: none !important;
}

.modal-title {
  text-align: center;
  flex: 1;
}

.popup-content {
  background: var(--popupListsBackground) !important;
  border-radius: 0 0 10px 10px !important;
  width: 300px !important;
  min-width: min-content !important;
  max-height: 485px;
  height: 485px;
  overflow: scroll;
  overflow-x: hidden;
  border: none !important;
  z-index: 3 !important;

  ul {
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    li a {
      margin: 0;
      color: white;

      &:hover {
        color: var(--textColor2Hover);
      }
    }

    li p {
      margin: 0;
    }
  }
}

.popupModal-content ul {
  padding-right: 0;
}

#twitchSidebar .animated:last-child > div {
  border: none;
}

.popup-content {
  scrollbar-color: rgb(240, 240, 240) rgba(0, 0, 0, 0) !important;
  scrollbar-width: thin !important;
}

div[aria-label='Countdown timer'] {
  font-size: 13px;

  & > div {
    height: 22px !important;
  }
}
.form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: transparent;
  outline: 0;
  box-shadow: none;
  border-bottom: 1px solid rgb(200, 200, 200);
}
input.form-control {
  border-bottom: 1px solid rgb(100, 100, 100);
}
body .btn-link {
  text-decoration: none;
  color: #aeaeae;

  &:hover {
    text-decoration: underline;
    color: #ffffff;
  }
}

.form-control:disabled,
.form-control[readonly] {
  color: #919191;
  cursor: not-allowed;
}
