.navbar.navbar-expand-lg.navbar-dark.bg-dark {
  height: 60px;
  width: 100%;
  background: transparent !important;
}

a.nav-link {
  font-size: 20px;
}

nav[mode='fixed'].navbar.navbar-expand-lg.navbar-dark.bg-dark {
  position: fixed;
  top: 10px;
  z-index: 2;
}

nav[shrink='true'].navbar.navbar-expand-lg.navbar-dark.bg-dark {
  height: 50px;
  background-color: transparent !important;

  a {
    font-size: 16px;
  }

  #NavigationProfileImage {
    width: 40px;
    box-shadow: 0px 0px 3px #000000;

    &:hover {
      box-shadow: 0px 0px 8px #000000;
    }
  }

  #NavigationProfileImageHoverOverlay {
    width: 40px;
  }
}

nav {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  height: 75px;

  .logo-link {
    padding: unset;
    height: 100%;

    .logo {
      margin: 0 10px;
      width: unset;
      height: calc(100% - 14px);
    }
  }
}

.navbar-nav a.nav-link {
  font-size: 1.15rem;
}
.navbar-expand-lg .navbar-nav a.nav-link {
  font-size: 1.15rem;
  padding-right: 0.63rem;
  padding-left: 0.63rem;
}

.container {
  .mr-auto.navbar-nav {
    justify-content: center;
    margin: auto;
  }

  .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.logo-link.nav-link {
  color: var(--navTextColorActive);
  font-size: 1.3rem;
}

#responsive-navbar-nav {
  height: inherit;
}

.navbar-nav {
  height: inherit;
  align-items: center;
}

.dropdown-menu {
  left: -60px !important;
  text-align: center;
  background-color: var(--navigationbarBackground);

  .dropdown-item {
    color: #ffffff !important;
    background: transparent !important;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #000000 !important;
      background: white !important;
    }
  }
}

.dropdown.nav-item,
.show.dropdown.nav-item {
  align-items: center;
  display: flex;
}

.popup-overlay {
  margin-top: 75px;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: var(--navTextColorActive);
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--navTextColor);
  transition: 250ms;
}

a.nav-link:hover,
a.nav-link:focus {
  color: var(--textColor1Hover) !important;
}
